import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Bootstrap and other CSS
import "bootstrap/dist/css/bootstrap.css";
import "assets/admin/scss/paper-dashboard.scss?v=1.3.0";
import "assets/admin/css/react search autocomplete.css";
import "assets/client/assets/css/meanmenu.min.css";
import "assets/client/assets/css/barfiller.css";
import "assets/client/assets/css/style.css";
import "assets/client/assets/css/responsive.css";
import "assets/client/assets/css/rtl.css";
import "assets/client/assets/fonts/font.css";

// Get the root element from the DOM
const container = document.getElementById("root");
const root = createRoot(container);

// Render the App component inside the BrowserRouter
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
