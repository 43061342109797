import React, { useEffect, useState } from "react";
import { Button, FormGroup, Form, Label } from "reactstrap";
import "./domains.css";
import { useNavigate } from "react-router";
import axiosApiInstance from "services/axios.inercept";

const BouncingDotsLoader = (props) => {
  return (
    <div className='bouncing-loader'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const Domains = () => {
  const [domain, setDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFirstFetch, setIsFirstFetch] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  // Function to fetch domain details using the given domain URL
  const fetchDomainDetails = async (domainLink) => {
    try {
      setLoading(true);
      const response = await axiosApiInstance.get(`${process.env.REACT_APP_BASE_URL}/tenants/tenant`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-mahaseel-domain": domainLink,
        },
      });

      if (response.data && Object.keys(response.data).length === 0) {
        setIsFirstFetch(false);
      }

      // Check if response data is empty or undefined
      if (response.data && Object.keys(response.data).length > 0) {
        // Store domain details in localStorage
        localStorage.setItem("domainDetails", JSON.stringify(response.data));

        // Optionally navigate or reload the page based on your logic
        navigate("/", { replace: true });
        window.location.reload();
      } else {
        // If response is empty, prompt the user to enter a domain
        setError("لم يتم العثور على المجال. الرجاء إدخال اسم المجال.");
      }
    } catch (err) {
      console.error("Error fetching domain details:", err);
      setError("الرجاء إدخال اسم المجال صالحًا.");
    } finally {
      setLoading(false);
    }
  };

  // On component mount, try to fetch domain details using the current URL
  useEffect(() => {
    // Get the hostname (domain)
    const hostname = window.location.hostname;

    // Get the protocol
    const protocol = window.location.protocol;

    // Combine them to form the complete domain name with protocol
    const domainWithProtocol = `${protocol}//${hostname}`;
    setIsFirstFetch(true);
    fetchDomainDetails(domainWithProtocol); // Try to fetch domain details automatically
  }, []);

  // Handle manual domain input from the user
  const handleInputChange = (e) => {
    setDomain(e.target.value);
  };

  // Handle manual domain form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (domain) {
      fetchDomainDetails(domain); // Fetch domain details using manually entered domain
    } else {
      setError("يرجى إدخال اسم النطاق"); // "Please enter a domain name"
    }
  };

  return (
    <div className='domain_container'>
      <img src='/assets/images/mahaseel-logo.png' alt='domain_img' className='mahaseel-domain-logo' />
      {isFirstFetch ? (
        <BouncingDotsLoader />
      ) : (
        <Form onSubmit={handleSubmit} className='domain_form'>
          <FormGroup className='domain_form_group'>
            <Label for='domain-input' className='arabic-label'>
              أدخل اسم النطاق
            </Label>
            <input
              id='domain-input'
              type='text'
              placeholder='أدخل اسم النطاق'
              value={domain}
              onChange={handleInputChange}
              className='domain-input'
            />
          </FormGroup>
          <Button type='submit' className='default-button default-button-3' disabled={loading}>
            {loading ? "جاري التحميل..." : "إرسال"}
          </Button>
          {error && <div className='error-message'>{error}</div>}
        </Form>
      )}
    </div>
  );
};

export default Domains;
